import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.256147038276!2d77.15151367460383!3d28.68198337563768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d024bdeffffff%3A0xc1b4b4b2c2bc466f!2sAggarwal%20Modern%20Bazar!5e0!3m2!1sen!2sin!4v1729235983905!5m2!1sen!2sin"
								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title="map"
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt="location" />
										

										<p>
										265 PLOT NO C-33 2ND FLR, AGGARWAL MODERN BAZAAR,<br />
										Keshav Puram ,North West Delhi, Delhi- 110035
										</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt="envelop" />
										<p>
											<strong>Email Id :</strong>classicpayinnovationspvt@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt="mobilebutton" />
										<p>
											<strong>Phone No :</strong>9953428263

										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
