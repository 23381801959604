import React, { useState } from 'react';
import { ModalWraper } from '../UI/styledConstants';
import IconClose from '../../assets/images/IconClose';
import logoBlack from '../../assets/images/logoBlack.svg';
import backgroundImg from '../../assets/images/bg-img.png';
import { downloadDivAsImage, numberToWords } from '../../utils/common';
import successImg from '../../assets/images/successimg.png';
const AepsReceipt = ({ closePop, transactionType, bankDataResp }) => {
	const [customCss, setCustonCss] = useState({});
	const heightCss = {
		height: '150px',
	};

	const handleDownloadClick = (title) => {
		setCustonCss({ height: 'auto' });
		setTimeout(() => {
			const divElement = document.getElementById('model');
			downloadDivAsImage(divElement, title, setCustonCss, heightCss);
		});
	};

	const handleCopy = (e, value) => {
		let target = e.target;

		navigator.clipboard
			.writeText(value)
			.then(() => {
				target.classList.add('active');

				(function (param) {
					setTimeout(() => {
						param.classList.remove('active');
					}, 1500);
				})(target);
			})
			.catch((err) => {
				console.error('Failed to copy text:', err);
			});
	};
	const regExpDebit = /^D/i;
	return (
		<ModalWraper url={backgroundImg}>
			<div className="modal modal-success modal-body-aeps" id="model">
				<div className="flex modal-header justify-between">
					<div className="logo">
						<img src={logoBlack} alt={'logo'} />
						<div>
							<p>Invoicing and payments</p>
							<p>powered by Classicpay Innovations </p>
						</div>
					</div>

					<div className="buttons">
						<button className="button-solid" style={{ marginRight: '8px' }} onClick={() => handleDownloadClick('send money receipt')}>
							Download
						</button>
						<button className="button-solid" onClick={() => window.print()}>
							Print
						</button>
					</div>
				</div>
				<div className="modal-close" onClick={closePop}>
					<IconClose />
				</div>
				{transactionType === 'enquiry' && (
					<div className="text-center modal-title">
						<h2 className="transactionMsg">Transaction Successful!</h2>
						<h3 className="amount">₹ {bankDataResp?.data?.bankAccountBalance}</h3>
						<p className="amount-text">{numberToWords(bankDataResp?.data?.bankAccountBalance)} rupee only</p>
					</div>
				)}
				{transactionType === 'Withdrawal' && (
					<div className="text-center modal-title">
						<h2 className="transactionMsg">Transaction Successful!</h2>
						<h3 className="amount">₹ {bankDataResp?.data?.payableValue}</h3>
						<p className="amount-text">{numberToWords(bankDataResp?.data?.payableValue)} rupee only</p>
					</div>
				)}

				<div className="modal-body ">
					<div className="txn-daital">
						<div className="flex justify-between txn-daital-h">
							<div>Send To</div>
							<div></div>
						</div>
						{transactionType === 'Withdrawal' && (
							<div className="flex justify-between txn-desc">
								<div className="txn-desc-right">
									<div>
										<span>Status</span>
									</div>
								</div>
								<div className="txn-desc-left">
									<div>
										<span>
											<div className="detail-desc success">
												<img src={successImg} alt={"success"} />
												SUCCESS
											</div>
										</span>
									</div>
								</div>
							</div>
						)}
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span>Bank Name</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>{bankDataResp?.data?.bankName}</span>
								</div>
							</div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span>Transaction ID</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>{bankDataResp?.data?.operatorId}</span>
								</div>
							</div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span>Reference No.</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>{bankDataResp?.data?.externalRef}</span>
								</div>
							</div>
						</div>
						<div className="flex justify-between txn-desc">
							<div className="txn-desc-right">
								<div>
									<span>Balance</span>
								</div>
							</div>
							<div className="txn-desc-left">
								<div>
									<span>₹ {bankDataResp?.data?.bankAccountBalance}</span>
								</div>
							</div>
						</div>
					</div>
					{bankDataResp?.data?.miniStatement.length > 0 && (
						<div className="MiniStatementList">
							<div className="flex justify-between txn-daital-h">
								<div>Mini Statemen</div>
								<div></div>
							</div>
							<div className="MiniStatement" style={customCss}>
								<table>
									<thead>
										<tr>
											<th>Particulars</th>
											<th>Date</th>
											{/* <th>Type</th> */}
											<th>amount</th>
										</tr>
									</thead>
									<tbody>
										{bankDataResp?.data?.miniStatement.length > 0 &&
											bankDataResp?.data?.miniStatement.map((val, index) => {
												return (
													<tr key={index}>
														<td>{val.narration}</td>
														<td>{val.date}</td>
														{/* <td>{(val.txnType==="D"?"Debit":"Credit")}</td> */}
														<td className={regExpDebit.test(val.txnType) ? 'red' : 'green'}>
															{regExpDebit.test(val.txnType) ? '-' : '+'} ₹ {val.amount}
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</div>
					)}
				</div>
			</div>
		</ModalWraper>
	);
};

export default AepsReceipt;
